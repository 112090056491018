@font-face {
  font-family: 'SB Sans Display';
  src: url('../../public/fonts/SBSansDisplay-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'SB Sans Display';
  src: url('../../public/fonts/SBSansDisplay-Bold.woff'),
    url('../../public/fonts/SBSansDisplay-Bold.woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'SB Sans Display';
  src: url('../../public/fonts/SBSansDisplay-Bold.woff'),
    url('../../public/fonts/SBSansDisplay-Bold.woff2');
  font-weight: 700;
}
